.signout-text {
  display: flex;
  justify-content: center;
  color: #effff1;
  cursor: pointer;
}

.signout-row {
  display: flex;
  margin-top: 40px;
}

.alert-style {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.alert-row {
  display: flex;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.button-row {
  display: flex;
  padding: 20px;
  margin-top: 20px;
}

.network-text {
  display: flex;
  text-align: center;
  font-size: 18px;
  padding: 20px;
}
