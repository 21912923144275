body {
  background-color: #2a5ca6 !important;
}

.margin-top-10 {
  margin-top: 10px;
}

.home-container {
  align-items: center;
  justify-content: center;
  padding-right: 60px;
  padding-left: 60px;
  padding-top: 20px;
}

.home-column {
  display: flex;
  height: 100vh;
  color: #464d54;
  justify-content: center;
  align-items: center;
}

.main-logo {
  width: 200px;
  height: auto;
}

.main-title {
  display: flex;
  color: #eeeef1;
  margin-bottom: 40px;
  justify-content: center;
}

.welcome-title {
  display: flex;
  color: #eeeef1;
  margin-top: 40px;
  justify-content: center;
}

.instruction-title {
  display: flex;
  justify-content: center;
}

.instruction-phone {
  color: #464d54;
}

.highlighted {
  background-color: #f5a623;
}

.phone-input {
  width: 60%;
  height: 50px;
  border-radius: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #464d54;
}

.alert-row {
  display: flex;
  text-align: center;
  margin-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

.margin-reset {
  margin: 0px;
}
